/* ==================================================================================================================
 * OpenGoSim Bluebell: app/shared/notifications.service.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

declare const Notification: any;

type Permission = 'denied' | 'granted' | 'default';

export interface AppNotification {
  body?: string;
  icon?: string;
  sticky?: boolean;
  title: string;
}

export const NOTIFICATION_TYPE = '[Bramble] Notification';

export class NotificationAction {
  readonly type = NOTIFICATION_TYPE;
  constructor(public payload: AppNotification) { }
}


@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  permission: Permission;

  constructor() {
    this.permission = this.isSupported() ? Notification.permission : 'denied';
  }

  requestPermission() {
    if ('Notification' in window) {
      Notification.requestPermission((status: any) => this.permission = status);
    }
  }

  isSupported() {
    return 'Notification' in window;
  }

  create(options?: AppNotification): any {
    return new Observable((obs: any) => {

      if (!('Notification' in window)) {
        obs.error('Notifications are not available in this environment');
        obs.complete();
      }

      if (this.permission !== 'granted') {
        obs.error(`The user hasn't granted you permission to send push notifications`);
        obs.complete();
      }

      const title: string = options.title;
      delete options.title;

      if (!options.icon) {
        options.icon = 'icon.png';
      }

      const n = new Notification(title, options);

      n.onshow = (e: any) => obs.next({notification: n, event: e});
      n.onclick = (e: any) => obs.next({notification: n, event: e});
      n.onerror = (e: any) => obs.error({notification: n, event: e});
      n.onclose = () => obs.complete();
    });
  }
}
